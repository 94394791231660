<template>
  <div class="">
    <YoutubeSearch />
    <!-- <YouTubePlayer :videoId="videoId" /> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'

// import YouTubePlayer from '@/components/YouTubePlayer.vue'
import YoutubeSearch from '@/components/YoutubeSearch.vue'

const videoId = ref<string>('1')
</script>

<style scoped lang="scss"></style>
