<template>
  <div class="bg-customDark min-h-screen">
    <div class="logo-container flex w-full text-center">
      <router-link to="/" class="logo">
        <h1 class="text-customGreenLight">Wirtualny Kris</h1>
      </router-link>
      <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/saved">Saved</router-link> |
        <router-link to="/channels">Channels</router-link>
      </nav>
    </div>

    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5a6977;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* Kontener loga */
.logo-container {
  position: relative;
  top: 0px; /* Odległość od góry */
  z-index: 1000; /* Wysoki z-index, aby logo było zawsze na wierzchu */
  align-content: center;
  justify-content: center;
}

/* Logo */
.logo {
  font-family: 'Montserrat';
  font-size: 2rem; /* Subtelniejszy rozmiar czcionki */
  font-weight: bold;
  position: relative;
  padding: 8px 16px; /* Mniejsze odstępy */
  cursor: pointer;
  position: absolute;
  left: 0;
  color: #42b983;
}

@media only screen and (max-width: 900px) {
  .logo-container {
    flex-direction: column;
  }
  .logo {
    position: relative;
  }
}
</style>
